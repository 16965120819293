<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll v-if="detail">
        <inputItem title="总结类型" :initialContent="(detail.type == 2 ? '出差总结' : '项目总结')" :disabled="true"></inputItem>
        <inputItem title="项目名称" v-if="project" :initialContent="project.name" :disabled="true"></inputItem>
        <inputItem title="招标二日期" v-if="project" :initialContent="formatDate(project.tower_date)" :disabled="true">
        </inputItem>

        <inputItem title="项目名称" v-if="travel" :initialContent="travel.project_name" :disabled="true"></inputItem>
        <inputItem title="出差目的" v-if="travel" :initialContent="travel.content" :disabled="true"></inputItem>
        <inputItem title="出差日期" v-if="travel"
          :initialContent="formatDate(travel.s_time) + '~' + formatDate(travel.e_time)" :disabled="true"></inputItem>

        <inputItem title="总结人员" :initialContent="detail.add_user_name" :disabled="true"></inputItem>
        <inputItem title="总结时间" :initialContent="formatDate(detail.udpate_time, 'yyyy-MM-dd hh:mm')" :disabled="true">
        </inputItem>
        <inputItem title="总结内容" v-model="detail.memo" :disabled="disabled" type="textarea"></inputItem>

        <div class="mobile-detail-step" v-if="detail && detail.state > 1">
          <Steps :current="flows.step" :status="flows.status" direction="vertical">
            <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName"
              :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
          </Steps>
        </div>
      </cube-scroll>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
  </div>
</template>

<script>
import { GetProjectSummary, AuditProjectSummary, SaveProjectSummary, CancelProjectSummary } from "@/api";
import { formatDate } from "@/utils/format.js";
import { loadDetailAction, submitAction, auditAction, cancelAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      project: null,
      travel: null,
      flows: [],
      buttons: [],
      disabled: false,
    };
  },
  methods: {
    formatDate: formatDate,
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveProjectSummary(1);
          break;
        case "提交审核":
          this.saveProjectSummary(2);
          break;
        case "审核通过":
          this.auditProjectSummary(true, "同意");
          break;
        case "审核拒绝":
          this.auditProjectSummary(false, "");
          break;
        case "撤回":
          this.cancelProjectSummary();
          break;
      }
    },
    saveProjectSummary: function (state) {
      this.detail.state = state;
      submitAction(this, SaveProjectSummary, { model: this.detail }, () => { this.$router.go(-1); }, () => { this.detail.state = 1; }, (state == 2), "是否确定提交？");
    },
    auditProjectSummary: function (pass, memo) {
      auditAction(this, AuditProjectSummary, this.id, pass, memo);
    },
    cancelProjectSummary: function () {
      cancelAction(this, CancelProjectSummary, this.id);
    },
    dataRequest: function () {
      loadDetailAction(this, GetProjectSummary, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.project = data.list[0].project;
        this.travel = data.list[0].travel;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
      });
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>
<style>
.mobile-input.textarea {
  height: 300px !important;
}
</style>